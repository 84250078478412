import {
  TxtHeaderPopupHintStyle,
  TxtHeaderPopupStyle,
} from "./TxtHeaderPopupStyle";

export default function TxtHeaderPopupGithubLink() {
  return (
    <TxtHeaderPopupStyle>
      Github @namerose
      <TxtHeaderPopupHintStyle>klik aja.</TxtHeaderPopupHintStyle>
    </TxtHeaderPopupStyle>
  );
}
