import {
  TxtHeaderPopupHintStyle,
  TxtHeaderPopupStyle,
} from "./TxtHeaderPopupStyle";

export default function TxtHeaderPopupEmail() {
  return (
    <TxtHeaderPopupStyle>
      syechrulimam@gmail.com
      <TxtHeaderPopupHintStyle>Klik Untuk Mengsalin.</TxtHeaderPopupHintStyle>
    </TxtHeaderPopupStyle>
  );
}
