import { keyframes } from "styled-components";

export const FadeIn = keyframes`
  from { 
    opacity: 0;
   }
  to { 
    opacity: 1;
   }
`;
